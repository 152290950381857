import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { RegisterBuyerRequest } from "../../platform-api/platform-api";
import { Frame } from "../../common/Layout.styled";

export type Props = RegisterBuyerRequest;

const Register: FunctionComponent<Props> = () => (
  <Frame>
    <FormattedMessage id="registerSupplier" />
  </Frame>
);

export default Register;
