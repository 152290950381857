import styled from "styled-components";

export const BaseButton = styled.button`
  letter-spacing: normal;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
`;

export const ActionButton = styled(BaseButton)`
  box-shadow: 0 2px 10px 0 rgba(76, 76, 76, 0.2);
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  height: 34px;
  padding: 3px 24px;
  &:disabled {
    background-color: #b0bec5;
    opacity: 0.75;
  }
  &:hover {
    opacity: 0.75;
  }
`;
