import styled from "styled-components";

export const Frame = styled.div`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  max-width: 450px;
  height: 90vh;
  border: 1px solid black;
  margin: 5vh auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: black;
  background-color: lightgray;
`;
