import styled from "styled-components";
import { ActionButton } from "../../common/Buttons.styled";

export const LoginButton = styled(ActionButton)`
  background-color: green;
`;

export const SignUpButton = styled(ActionButton)`
  background-color: red;
`;
