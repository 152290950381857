import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Frame } from "../../common/Layout.styled";
import { BuyButton, InvestButton, SellButton } from "./SignUp.styled";

const SignUp = () => (
  <Frame>
    <FormattedMessage id="whatWouldYouLikeToDo" />
    <Link to="/buyer/register">
      <BuyButton>
        <FormattedMessage id="buy" />
      </BuyButton>
    </Link>
    <Link to="/supplier/register">
      <SellButton>
        <FormattedMessage id="sell" />
      </SellButton>
    </Link>
    <Link to="/funder/register">
      <InvestButton>
        <FormattedMessage id="invest" />
      </InvestButton>
    </Link>
  </Frame>
);

export default SignUp;
