import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";

import { Input, Form } from "../../common/Forms.styled";
import { Frame } from "../../common/Layout.styled";
import { Label } from "../../common/Text.styled";
import { LoginButton } from "./Login.styled";

export type Props = {
  rememberMe: boolean;
  username: string;
  onSubmit: () => void;
};

const Login: FunctionComponent<Props> = ({ onSubmit = () => {} }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  console.log(errors);
  return (
    <Frame>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>
          <FormattedMessage id="email" />
        </Label>
        <Input
          {...register("email", { required: true, maxLength: 80 })}
          placeholder="email"
        />

        <Label>
          <FormattedMessage id="password" />
        </Label>
        <Input
          {...register("password", { required: true, maxLength: 80 })}
          type="password"
          placeholder="password"
        />
        <LoginButton>
          <FormattedMessage id="login" />
        </LoginButton>
      </Form>
    </Frame>
  );
};

export default Login;
