import styled from "styled-components";

export const Input = styled.input`
  background: transparent;
  border: 1;
  border-radius: 4px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
