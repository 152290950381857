import styled from "styled-components";
import { ActionButton } from "../../common/Buttons.styled";

export const BuyButton = styled(ActionButton)`
  background-color: green;
`;

export const SellButton = styled(ActionButton)`
  background-color: yellow;
`;

export const InvestButton = styled(ActionButton)`
  background-color: blue;
`;
