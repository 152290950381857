import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Frame } from "../../common/Layout.styled";
import { RegisterBuyerRequest } from "../../platform-api/platform-api";

export type Props = RegisterBuyerRequest;

const RegisterFunder: FunctionComponent<Props> = () => (
  <Frame>
    <FormattedMessage id="registerFunder" />
  </Frame>
);

export default RegisterFunder;
