import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Frame } from "../../common/Layout.styled";
import { RegisterBuyerRequest } from "../../platform-api/platform-api";

export type Props = RegisterBuyerRequest;

const RegisterBuyer: FunctionComponent<Props> = () => (
  <Frame>
    <FormattedMessage id="registerBuyer" />
    <FormattedMessage id="registerBuyer2" />
  </Frame>
);

export default RegisterBuyer;
