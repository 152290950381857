import React from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./features/main/Main";
import SignUp from "./features/main/SignUp";
import Login from "./features/login/Login";
import en from "./i18n/en.json";
import RegisterBuyer from "./features/buyer/RegisterBuyer";
import RegisterFunder from "./features/funder/RegisterFunder";
import RegisterSupplier from "./features/supplier/RegisterSupplier";

function App() {
  return (
    <IntlProvider messages={{ ...en }} locale="en" defaultLocale="en">
      <Router>
        <Switch>
          <Route component={Main} path="/" exact />
          <Route component={SignUp} path="/signup" exact />
          <Route component={Login} path="/login" exact />
          <Route component={RegisterBuyer} path="/buyer/register" exact />
          <Route component={RegisterFunder} path="/funder/register" exact />
          <Route component={RegisterSupplier} path="/supplier/register" exact />
        </Switch>
      </Router>
    </IntlProvider>
  );
}

export default App;
