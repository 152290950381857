import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Frame } from "../../common/Layout.styled";
import { LoginButton, SignUpButton } from "./Main.styled";

const Main = () => (
  <Frame>
    <Link to="login">
      <LoginButton>
        <FormattedMessage id="login" />
      </LoginButton>
    </Link>
    <Link to="signup">
      <SignUpButton>
        <FormattedMessage id="signUp" />
      </SignUpButton>
    </Link>
  </Frame>
);

export default Main;
